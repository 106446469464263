export const dbCollections = {
  ressources: 'Ressources',
  gallery: 'Gallery',
  visitsStats: 'Visits',
  downloadStats: 'Downloads',
  users: 'Users',
  categories: 'Categories',
}

export const dbStorageNamespaces = {
  ressources: 'ressources',
  gallery: 'gallery',
  categories: 'categories',
  users: 'users',
}

export const mockupAnimationImageSequence = [
  "/images/promo/app/sequence/out1.png",
  "/images/promo/app/sequence/out2.png",
  "/images/promo/app/sequence/out3.png",
  "/images/promo/app/sequence/out4.png",
  "/images/promo/app/sequence/out5.png",
  "/images/promo/app/sequence/out6.png",
  "/images/promo/app/sequence/out7.png",
  "/images/promo/app/sequence/out8.png",
  "/images/promo/app/sequence/out9.png",
  "/images/promo/app/sequence/out10.png",
  "/images/promo/app/sequence/out11.png",
  "/images/promo/app/sequence/out12.png",
  "/images/promo/app/sequence/out13.png",
  "/images/promo/app/sequence/out14.png",
  "/images/promo/app/sequence/out15.png",
  "/images/promo/app/sequence/out16.png",
  "/images/promo/app/sequence/out17.png",
  "/images/promo/app/sequence/out18.png",
  "/images/promo/app/sequence/out19.png",
  "/images/promo/app/sequence/out20.png",
  "/images/promo/app/sequence/out21.png",
  "/images/promo/app/sequence/out22.png",
  "/images/promo/app/sequence/out23.png",
  "/images/promo/app/sequence/out24.png",
  "/images/promo/app/sequence/out25.png",
  "/images/promo/app/sequence/out26.png",
  "/images/promo/app/sequence/out27.png",
  "/images/promo/app/sequence/out28.png",
  "/images/promo/app/sequence/out29.png",
  "/images/promo/app/sequence/out30.png",
  "/images/promo/app/sequence/out31.png",
  "/images/promo/app/sequence/out32.png",
  "/images/promo/app/sequence/out33.png",
  "/images/promo/app/sequence/out34.png",
  "/images/promo/app/sequence/out35.png",
  "/images/promo/app/sequence/out36.png",
  "/images/promo/app/sequence/out37.png",
  "/images/promo/app/sequence/out38.png",
  "/images/promo/app/sequence/out39.png",
  "/images/promo/app/sequence/out40.png",
  "/images/promo/app/sequence/out41.png",
  "/images/promo/app/sequence/out42.png",
  "/images/promo/app/sequence/out43.png",
  "/images/promo/app/sequence/out44.png",
  "/images/promo/app/sequence/out45.png",
  "/images/promo/app/sequence/out46.png",
  "/images/promo/app/sequence/out47.png",
  "/images/promo/app/sequence/out48.png",
  "/images/promo/app/sequence/out49.png",
  "/images/promo/app/sequence/out50.png",
  "/images/promo/app/sequence/out51.png",
  "/images/promo/app/sequence/out52.png",
  "/images/promo/app/sequence/out53.png",
  "/images/promo/app/sequence/out54.png",
  "/images/promo/app/sequence/out55.png",
  "/images/promo/app/sequence/out56.png",
  "/images/promo/app/sequence/out57.png",
  "/images/promo/app/sequence/out58.png",
  "/images/promo/app/sequence/out59.png",
  "/images/promo/app/sequence/out60.png",
  "/images/promo/app/sequence/out61.png",
  "/images/promo/app/sequence/out62.png",
  "/images/promo/app/sequence/out63.png",
  "/images/promo/app/sequence/out64.png",
  "/images/promo/app/sequence/out65.png",
  "/images/promo/app/sequence/out66.png",
  "/images/promo/app/sequence/out67.png",
  "/images/promo/app/sequence/out68.png",
  "/images/promo/app/sequence/out69.png",
  "/images/promo/app/sequence/out70.png"
]